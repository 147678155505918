import Swiper from "../vendor/swiper/swiper.min.js";

//Mobile menu
let accItem = document.getElementsByClassName("mobile-menu__item");
let mobMenuBtn = document.getElementsByClassName("mobile-menu__button");

for (i = 0; i < mobMenuBtn.length; i++) {
  mobMenuBtn[i].addEventListener("click", toggleItem, false);
}
function toggleItem() {
  let itemClass = this.parentNode.className;
  for (i = 0; i < accItem.length; i++) {
    accItem[i].className = "mobile-menu__item collapsed";
  }
  if (itemClass == "mobile-menu__item collapsed") {
    this.parentNode.className = "mobile-menu__item active";
  }
}

//Search input hover
let searchInput = document.getElementById("searchField");
let clearInputIcon = document.getElementById("searchSwitcherCloseN");
let collapsedButton = document.getElementById("collapseButtonClose");
let searchRow = document.getElementById("searchToShow");
let illusion = document.getElementById("illusion");

if (typeof searchInput != "undefined" && searchInput != null) {
  searchInput.onfocus = function () {
    if (window.innerWidth < 1367) {
      //searchInput.classList.add('sidenav-mobile__wide');
      //collapsedButton.style.display = "none";
      //searchRow.style.width = 100 + '%';
      if (illusion) illusion.style.display = "none";
    }
    if (typeof clearInputIcon != "undefined" && clearInputIcon != null) {
      clearInputIcon.style.display = "block";
    }
  };
}

function searchInputOnBlur() {
  if (window.innerWidth < 1367) {
    searchInput.value = "";
    clearInputIcon.style.display = "none";
    searchRow.style.width = "auto";
    //collapsedButton.style.display = "block";
    //searchInput.classList.remove('sidenav-mobile__wide');
    searchInput.classList.remove("is-blur");
    if (illusion) illusion.style.display = "block";
  } else {
    searchInput.value = "";
    clearInputIcon.style.display = "none";
    searchInput.classList.remove("is-blur");
  }
}

jQuery("body").on("click", ".search-autocomplete-item", function () {
  searchInputOnBlur();
});

jQuery("#overlay").on("click", function () {
  searchInputOnBlur();
});

jQuery("#searchField").on("blur", function () {
  jQuery("#searchField").addClass("is-blur");
});

function clearInput() {
  searchInput.value = "";
  searchInput.style.borderColor = "#B0B0B0";
  clearInputIcon.style.display = "none";
  if (window.innerWidth < 1367) {
    //searchInput.classList.remove('sidenav-mobile__wide');
    //collapsedButton.style.display = "block";
    searchRow.style.width = "auto";
    searchInput.classList.remove("is-blur");
    if (illusion) illusion.style.display = "block";
  } else {
    searchInput.classList.remove("is-blur");
  }
}
if (clearInputIcon !== null) {
  clearInputIcon.addEventListener("click", clearInput);
}

//Service package slider
let packageSwiper = new Swiper(".package-container", {
  slidesPerView: "auto",
  autoplay: false,
  keyboard: false,
  allowTouchMove: false,
  mousewheel: false,
  preventClicks: false,
  spaceBetween: 0,
  breakpoints: {
    1200: {
      slidesPerView: "auto",
      spaceBetween: 30,
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
      navigation: {
        nextEl: ".package-next",
        prevEl: ".package-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
    576: {
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
      slidesPerView: "auto",
      initialSlide: 0,
      centeredSlides: true,
      spaceBetween: 18,
      navigation: {
        nextEl: ".package-next",
        prevEl: ".package-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
  },
});

//Desktop review slider
let reviewSwiper = new Swiper(".feedback-container", {
  slidesPerView: 4.2,
  slidesPerGroup: 4,
  spaceBetween: 30,
  grabCursor: true,
  slidesOffsetAfter: 30,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    1200: {
      slidesPerView: 1.3,
      slidesPerGroup: 1,
      spaceBetween: 20,
      initialSlide: 0,
      centeredSlides: true,
      slidesOffsetAfter: 0,
    },
    991: {
      slidesPerView: 1.3,
      slidesPerGroup: 1,
      spaceBetween: 20,
      initialSlide: 0,
      centeredSlides: true,
    },
    576: {
      slidesPerView: 1.3,
      slidesPerGroup: 1,
      spaceBetween: 20,
      initialSlide: 0,
      centeredSlides: true,
    },
  },
});
jQuery(function ($) {
  $(document).ready(function () {
    reviewSwiper.on("slideChange", function () {
      if (this.activeIndex === 0) {
        if ($(window).width() > 1200) {
          document.querySelector(
            ".feedback-row__gradient--left"
          ).style.display = "none";
        }
        document
          .querySelector(".slide-0")
          .querySelector(".swiper-pagination-bullet")
          .classList.add("swiper-pagination-bullet-active");
        document
          .querySelector(".slide-4")
          .querySelector(".swiper-pagination-bullet")
          .classList.remove("swiper-pagination-bullet-active");
        document
          .querySelector(".slide-8")
          .querySelector(".swiper-pagination-bullet")
          .classList.remove("swiper-pagination-bullet-active");
      } else if (this.activeIndex === 4) {
        if ($(window).width() > 1200) {
          document.querySelector(
            ".feedback-row__gradient--left"
          ).style.display = "block";
          document.querySelector(
            ".feedback-row__gradient--right"
          ).style.display = "block";
        }
        document
          .querySelector(".slide-0")
          .querySelector(".swiper-pagination-bullet")
          .classList.remove("swiper-pagination-bullet-active");
        document
          .querySelector(".slide-4")
          .querySelector(".swiper-pagination-bullet")
          .classList.add("swiper-pagination-bullet-active");
        document
          .querySelector(".slide-8")
          .querySelector(".swiper-pagination-bullet")
          .classList.remove("swiper-pagination-bullet-active");
      } else if (this.activeIndex === 7 || this.activeIndex === 8) {
        if ($(window).width() > 1200) {
          document.querySelector(
            ".feedback-row__gradient--left"
          ).style.display = "block";
          document.querySelector(
            ".feedback-row__gradient--right"
          ).style.display = "none";
        }
        document
          .querySelector(".slide-0")
          .querySelector(".swiper-pagination-bullet")
          .classList.remove("swiper-pagination-bullet-active");
        document
          .querySelector(".slide-4")
          .querySelector(".swiper-pagination-bullet")
          .classList.remove("swiper-pagination-bullet-active");
        document
          .querySelector(".slide-8")
          .querySelector(".swiper-pagination-bullet")
          .classList.add("swiper-pagination-bullet-active");
      } else {
      }
    });

    if (document.querySelector(".slide-0")) {
      document
        .querySelector(".slide-0")
        .addEventListener("click", function (e) {
          e.preventDefault();
          reviewSwiper.slideTo(0, 200);
          if (
            document
              .querySelector(".swiper-slide-0")
              .classList.contains("swiper-slide-active")
          ) {
            document
              .querySelector(".slide-0")
              .querySelector(".swiper-pagination-bullet")
              .classList.add("swiper-pagination-bullet-active");
            document
              .querySelector(".slide-4")
              .querySelector(".swiper-pagination-bullet")
              .classList.remove("swiper-pagination-bullet-active");
            document
              .querySelector(".slide-8")
              .querySelector(".swiper-pagination-bullet")
              .classList.remove("swiper-pagination-bullet-active");
          }
        });
    }

    if (document.querySelector(".slide-4")) {
      document
        .querySelector(".slide-4")
        .addEventListener("click", function (e) {
          e.preventDefault();
          reviewSwiper.slideTo(4, 200);
          if (
            document
              .querySelector(".swiper-slide-4")
              .classList.contains("swiper-slide-active")
          ) {
            document
              .querySelector(".slide-0")
              .querySelector(".swiper-pagination-bullet")
              .classList.remove("swiper-pagination-bullet-active");
            document
              .querySelector(".slide-4")
              .querySelector(".swiper-pagination-bullet")
              .classList.add("swiper-pagination-bullet-active");
            document
              .querySelector(".slide-8")
              .querySelector(".swiper-pagination-bullet")
              .classList.remove("swiper-pagination-bullet-active");
          }
        });
    }

    if (document.querySelector(".slide-8")) {
      document
        .querySelector(".slide-8")
        .addEventListener("click", function (e) {
          e.preventDefault();
          reviewSwiper.slideTo(8, 200);
          if (
            document
              .querySelector(".swiper-slide-8")
              .classList.contains("swiper-slide-active")
          ) {
            document
              .querySelector(".slide-0")
              .querySelector(".swiper-pagination-bullet")
              .classList.remove("swiper-pagination-bullet-active");
            document
              .querySelector(".slide-4")
              .querySelector(".swiper-pagination-bullet")
              .classList.remove("swiper-pagination-bullet-active");
            document
              .querySelector(".slide-8")
              .querySelector(".swiper-pagination-bullet")
              .classList.add("swiper-pagination-bullet-active");
          }
        });
    }
  });
});

//Checklist page days slider
let daySwiper = new Swiper(".day-container", {
  slidesPerView: 4,
  slidesPerColumn: 2,
  slidesPerColumnFill: "row",
  autoplay: false,
  keyboard: false,
  allowTouchMove: false,
  mousewheel: false,
  breakpoints: {
    1280: {
      slidesPerView: "auto",
      slidesPerColumn: 1,
      spaceBetween: 30,
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
      navigation: {
        nextEl: ".swiper-button-next-unique",
        prevEl: ".swiper-button-prev-unique",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    },
  },
});

//Kyrklig, borgerlid and direktkremation page services compare slider
let tableCompareSwiper = new Swiper(".table-compare--slider", {
  slidesPerView: 3,
  spaceBetween: 20,
  grabCursor: true,
  autoplay: false,
  keyboard: false,
  allowTouchMove: false,
  mousewheel: false,

  breakpoints: {
    991: {
      slidesPerView: 2,
      allowTouchMove: true,
      initialSlide: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    767: {
      slidesPerView: 2,
      allowTouchMove: true,
      initialSlide: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    576: {
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
      slidesPerView: "auto",
      initialSlide: 0,
      centeredSlides: true,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
  },
});

//Obituary page steps slider
let stepSwiper = new Swiper(".step-container", {
  slidesPerView: 3,
  spaceBetween: 30,
  autoplay: false,
  keyboard: false,
  allowTouchMove: false,
  mousewheel: false,
  breakpoints: {
    1200: {
      slidesPerView: 2.5,
      spaceBetween: 30,
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
    },
    991: {
      slidesPerView: 2.5,
      spaceBetween: 30,
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
    },
    767: {
      slidesPerView: 1.5,
      spaceBetween: 30,
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
    },
    576: {
      slidesPerView: 1.2,
      spaceBetween: 30,
      allowTouchMove: true,
      simulateTouch: true,
      grabCursor: true,
    },
  },
});

/*************************************
 Display floating Charlotte Runius with phone on mobile (experiment page)
 **************************************/
let cityInput = document.querySelector(".location-near__select");

function addBodyClass() {
  document.body.classList.add("input-focused");
}
function removeBodyClass() {
  document.body.classList.remove("input-focused");
}

if (window.innerWidth < 576) {
  function choicesLoad() {
    let choiceListInput = document.querySelector(
      ".location-near__select .choices__list--dropdown input"
    );
    if (typeof choiceListInput != "undefined" && choiceListInput != null) {
      choiceListInput.onfocus = function () {
        addBodyClass();
      };
      choiceListInput.onblur = function () {
        removeBodyClass();
      };
    }
  }
  window.onload = choicesLoad;

  jQuery(function ($) {
    $(cityInput).click(function () {
      $("html,body").animate(
        {
          scrollTop: $(cityInput).offset().top - 65,
        },
        "slow"
      );
    });
  });

  if (typeof cityInput != "undefined" && cityInput != null) {
    cityInput.addEventListener("click", addBodyClass);
  }
}

// if(document.body.classList.contains('home')) {
// 	let floatCharlotte = document.getElementById('floatCharlotte');
// 	let bannerHeight = document.getElementById('mainImage').offsetHeight;
// 	let notifyHeight = 0;
// 	let floatCharlotteBottom = notifyHeight + 15 + "px";
//
// 	let scrollFunc = function () {
// 		var y = window.scrollY;
// 		// if (document.getElementsByClassName('corona-notify-wrapper').length){
// 		// 	notifyHeight = document.getElementsByClassName('corona-notify-wrapper')[0].offsetHeight;
// 		// 	floatCharlotteBottom = notifyHeight + 15 + "px";
// 		// }
// 		if (y >= bannerHeight) {
// 			floatCharlotte.style.bottom = floatCharlotteBottom;
// 		} else {
// 			floatCharlotte.style.bottom = "-65px";
// 		}
// 	};
// 	window.addEventListener("scroll", scrollFunc);
// }

/*************************************
 Position #floatCharlotte relatively to
 .corona-notify and #cookie-notice
 **************************************/
jQuery(function ($) {
  $(document).ready(function () {
    if (
      $("#cookie-notice").length !== 0 &&
      $("#cookie-notice").css("display") == "block" &&
      $("#cookie-notice").css("visibility") == "visible"
    ) {
      $("#cookie-notice").addClass("shown");
    }

    let cookieNoticeHeight =
      $("#cookie-notice.shown > .cookie-notice-container").outerHeight() !==
      null
        ? $("#cookie-notice.shown > .cookie-notice-container").outerHeight()
        : 0;

    // function setFloatCharlottePos(){
    // 	let floatCharlotteBottomNew = "15px";
    // 	let cookieNoticeBottomNew = "0";
    //
    // 	//let coronaHeight = $('.corona-notify-wrapper').outerHeight() !== null ? $('.corona-notify-wrapper').outerHeight() : 0;
    //
    // 	//cookieNoticeBottomNew = coronaHeight + "px";
    // 	floatCharlotteBottomNew = cookieNoticeHeight + 15 + "px";
    // 	$('#cookie-notice').css('bottom', cookieNoticeBottomNew);
    // 	$('#floatCharlotte').css('bottom', floatCharlotteBottomNew);
    // }

    // function setFloatCharlottePosScroll() {
    // 	$('#cookie-notice').removeClass('shown');
    // 	let curFloatCharlotteBottomPos = parseFloat($('#floatCharlotte').css('bottom'));
    // 	let floatCharlotteBottomClick = curFloatCharlotteBottomPos - cookieNoticeHeight + "px";
    // 	$('#floatCharlotte').css('bottom', floatCharlotteBottomClick);
    // 	cookieNoticeHeight = 0;
    // }

    // initial position on page load
    // if( $('body').hasClass('city-landing') || $('body').hasClass('begravning-page') || $('body').hasClass('page-67424') && $('#floatCharlotte').length !== 0 ){
    // 	// wait 2 seconds before popping up, to be sure #cookie-notice is in DOM
    // 	window.setTimeout(
    // 		setFloatCharlottePos(),
    // 		2000
    // 	);
    // }

    // change position on cookie-notice disappearing upon close click
    // $('#cn-accept-cookie').click(function (e) {
    // 	setFloatCharlottePosScroll();
    // });

    // change position on cookie-notice disappearing upon scroll
    // window.addEventListener('scroll', setFloatCharlottePosOnScroll);
    // $(window).scroll(function () {
    // 	if ($('#cookie-notice').css('display') !== 'block' && $('#cookie-notice').hasClass('shown')) {
    // 		setFloatCharlottePosScroll();
    // 	}
    // });
  });
});

if (
  document.body.classList.contains("city-exp") ||
  document.body.classList.contains("begravning-page") ||
  document.body.classList.contains("page-67424")
) {
  //let floatCharlotteCity = document.getElementById('floatCharlotte');
  let heroImage = document.getElementById("heroImage");
  let bannerHeightCity = 0;
  if (typeof heroImage != "undefined" && heroImage != null) {
    bannerHeightCity = document.getElementById("heroImage").offsetHeight;
  }
  let notifyHeight = 0;
  //let floatCharlotteBottom = notifyHeight + 15 + "px";

  // let scrollFuncCity = function fixPhone() {
  // 	// if (document.getElementsByClassName('corona-notify-wrapper').length){
  // 	// 	notifyHeight = document.getElementsByClassName('corona-notify-wrapper')[0].offsetHeight;
  // 	// 	floatCharlotteBottom = notifyHeight + 15 + "px";
  // 	// }
  // 	if (window.scrollY >= bannerHeightCity) {
  // 		floatCharlotteCity.style.bottom = floatCharlotteBottom;
  // 	} else {
  // 		floatCharlotteCity.style.bottom = "-65px";
  // 	}
  // }
  if (window.innerWidth < 576) {
    //window.addEventListener('scroll', scrollFuncCity);

    jQuery("#accordion").on("shown.bs.collapse", function () {
      var panel = jQuery(".tabs-title__h3");

      jQuery("html, body").animate(
        {
          scrollTop: panel.offset().top - 100,
        },
        0
      );
    });
  }

  if (window.innerWidth < 576) {
    let planeraParent = document.querySelector(
      ".text-description--city .text-description__info"
    );
    let planeraChild = document.querySelectorAll(
      ".text-description--city .text-description__info > *"
    );
    let planeraButton = document.getElementById("hiddenPlanera");
    let planeraButtonWrapper = document.querySelector(".planera-more");

    if (planeraChild.length > 1) {
      planeraChild.item(0).classList.add("show-first-child");
    } else if (
      planeraChild.length == 1 &&
      planeraChild.item(0).offsetHeight < 140
    ) {
      planeraButtonWrapper.style.display = "none";
    } else if (
      planeraChild.length == 1 &&
      planeraChild.item(0).offsetHeight > 140
    ) {
      planeraChild.item(0).classList.add("show-first-child");
    } else {
    }

    jQuery(planeraButton).on("click touch", function () {
      for (let i = 1; i < planeraChild.length; i++) {
        planeraChild.item(i).classList.toggle("show-child");
        if (planeraChild.item(i).classList.contains("show-child")) {
          planeraButton.innerHTML = "Stäng";
        } else {
          planeraButton.innerHTML = "Läs mer";
        }
      }
      planeraChild.item(0).classList.toggle("show-first-child");
      planeraButton.classList.toggle("planera-more__collapsed");
      if (planeraButton.classList.contains("planera-more__collapsed")) {
        jQuery("html, body").animate(
          {
            scrollTop: jQuery(planeraParent).offset().top - 180,
          },
          1000
        );
      } else {
        jQuery("html, body").animate(
          {
            scrollTop: jQuery(planeraButton).offset().top - 100,
          },
          1000
        );
      }
    });
  }
}

/*************************************
 Change quote and youtube icon on hover (homepage)
 **************************************/
jQuery(function ($) {
  $(document).ready(function () {
    $(".review-column__image").hover(
      function () {
        $(this)
          .find("img")
          .attr(
            "src",
            "/wp-content/themes/fenix4/dist/images/icons/icon_play_active.png"
          );
      },
      function () {
        $(this)
          .find("img")
          .attr(
            "src",
            "/wp-content/themes/fenix4/dist/images/icons/icon_play.png"
          );
      }
    );

    (function () {
      $("#kyrkor-tab").on("click", function () {
        $("#nav-kyrkor-tab").trigger("click");
      });

      $("#begravningsplatser-tab").on("click", function () {
        $("#nav-begravningsplatser-tab").trigger("click");
      });
    })();

    if (window.innerWidth < 991) {
      $("#kyrkor-tab, #begravningsplatser-tab").click(function () {
        $("html,body").animate(
          {
            scrollTop: $(".fixedwidth-wrapper--accordion-title").offset().top,
          },
          "slow"
        );
      });
    } else {
      $("#kyrkor-tab, #begravningsplatser-tab").click(function () {
        $("html,body").animate(
          {
            scrollTop: $(".partner-tabs").offset().top - 150,
          },
          "slow"
        );
      });
    }
  });
});

/************Unfold the table************/
function showHidden() {
  var x = document.getElementById("contentHidden");
  var buttonHidden = document.getElementById("hiddenButton");
  if (x.style.display === "none") {
    x.style.display = "block";
    buttonHidden.innerHTML = "Stäng";
  } else {
    x.style.display = "none";
    buttonHidden.innerHTML = "Lär dig mer";
  }
}

function showHiddenT() {
  var x = document.getElementById("contentHiddenT");
  var buttonHidden = document.getElementById("hiddenButtonT");
  if (x.style.display === "none") {
    x.style.display = "block";
    buttonHidden.innerHTML = "Stäng";
  } else {
    x.style.display = "none";
    buttonHidden.innerHTML = "Lär dig mer";
  }
}

function showHiddenF() {
  var x = document.getElementById("contentHiddenF");
  var buttonHidden = document.getElementById("hiddenButtonF");
  if (x.style.display === "none") {
    x.style.display = "block";
    buttonHidden.innerHTML = "Stäng";
  } else {
    x.style.display = "none";
    buttonHidden.innerHTML = "Lär dig mer";
  }
}

var acc = document.getElementsByClassName("social");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
    this.classList.toggle("active");
    this.classList.toggle("collapsed");
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  });
}
