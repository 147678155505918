jQuery(function ($) {

    // const elements = $('.near-location');
    //
    // elements.each(function (index, el) {
    //     new Choices(el, {
    //             placeholder: true,
    //             silent: true,
    //             searchPlaceholderValue: '',
    //             itemSelectText: '',
    //             noResultsText: $(el).data('no-results'),
    //             shouldSort: false,
    //             position: "bottom",
    //             searchResultLimit: 100,
    //             searchFields: ['label'],
    //             fuseOptions: {
    //                 includeMatches: true,
    //                 threshold: 0.0,
    //                 tokenize: true,
    //             },
    //             classNames: {
    //                 containerOuter: 'choices cities-list--choices',
    //             }
    //         }
    //     );
    // }).on('choice', function(event){
    //     window.location.href = `/begravningsbyra/${event.detail.choice.value}/`;
    // });

    // Helper function for setting a Cookie
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /**
     * Five cases for initial creation of the Choices instances [refactored]
     * to handle 5 cases of city dropdowns for further AJAX calls to
     * 'destroy' empty via "window" object, then create new populated with city list:
     * 1 - Header
     * 2 - Body, same in mob
     * 3 - Mobile: header mob nav
     * 4 - Mobile: sticky - this one doesn't exist in Start (Home), only in city pages
     * 5 - inside Borgerlig begravning page
     */

    function choiceMadeSetCookieGo(event){
        let city_value = `${event.detail.choice.value}`;
        setCookie("fenix4city", city_value, 365);
        if(city_value !== '') {
            window.location.href = `/begravningsbyra/` + city_value + `/`;
        } else {
            window.location.href = `/`;
        }
    }

    const elements = $('.near-location');
    elements.each(function (index, el) {
        let el_id = $(el).attr('id');
        let jQSelector = $('#' + el_id);
        let el_name = jQSelector.attr('name');
        // console.log("#: " + index +  " | el_id: " + el_id + " | jQSelector: " + jQSelector + " | el_name: " + el_name);
        let citySelectorJS = document.getElementById(el_id);

        window[el_name] = new Choices(el, {
                placeholder: true,
                silent: true,
                searchPlaceholderValue: '',
                itemSelectText: '',
                noResultsText: $(el).data('no-results'),
                shouldSort: false,
                position: "bottom",
                searchResultLimit: 100,
                searchFields: ['label'],
                fuseOptions: {
                    includeMatches: true,
                    threshold: 0.0,
                    tokenize: true,
                },
                classNames: {
                    containerOuter: 'choices cities-list--choices',
                }
            }
        );
    }).on('choice', function(event){
        choiceMadeSetCookieGo(event);
    });
});
