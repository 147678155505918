jQuery(function ($) {
    $('.ctr-button').click(function(e){
       let span = $(this).find('span');
       $(this).removeClass('gtm-strongintent');
       $(this).find('span').removeClass('gtm-strongintent');
       //console.log(span);
       span.html('<a href="tel:+4620828282">020-82 82 82</a>');

        if (window.__ctm) {
            window.__ctm.main.runNow();
        }
    });

    $('.ctr-organiser__button').click(function(e){
        $(this).closest('.ctr-organiser__button_wrp').toggleClass('open');
        let span = $('.ctr-organiser__ring');
        $(this).toggleClass('gtm-strongintent');
        // console.log(span);
        span.html('<a class="ctr-organiser__phone" href="tel:+4620828282">020-82 82 82</a>');
        if (window.__ctm) {
            window.__ctm.main.runNow();
        }
    });

    $('.ctr-textlink').click(function(e){
        let span = $(this).find('span');
        $(this).find('span').removeClass('gtm-strongintent');
        span.html('<a href="tel:+4620828282">020-82 82 82</a>');

        if (window.__ctm) {
            window.__ctm.main.runNow();
        }
        e.stopImmediatePropagation();
    });

    $('.ctr-kyrklig-btn').click(function(e){
        let span = $(this).find('span');
        $(this).removeClass('gtm-strongintent');
        $(this).find('span').removeClass('gtm-strongintent');
        span.html('<a href="tel:+4620828282"><button type="button" class="btn btn-blue" title="Kontakta oss för att komma igång med er planering">020-82 82 82</button></a>');

        if (window.__ctm) {
            window.__ctm.main.runNow();
        }
    });

    $('.ctr-ringoss').click(function(e){
        let span = $(this).find('span');
        $(this).removeClass('gtm-strongintent');
        $(this).find('span').removeClass('gtm-strongintent');
        span.html('<a href="tel:+4620828282">020-82 82 82</a>');

        if (window.__ctm) {
            window.__ctm.main.runNow();
        }
    });
});
