jQuery(function ($) {
    $( document ).ready(function() {
        let loader = '<div class="search-autocomplete__loading"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>';

        $('#searchToShow').append('<div class="search-autocomplete-wrapper"></div>');

        $('#searchSwitcher').on('click', function() {
            // updateElementStylesOnSearchInit();
        });
        $('#searchSwitcherCloseN').on('click', function () {
            // updateElementStylesOnSearchInit();
            clearAndHideWrapper($('.search-autocomplete-wrapper'));
        });
        $('#searchField').on('keyup', function (e) { // change keyup
            // updateElementStylesOnSearchInit('add');
            var autocomp_wrapper = $('.search-autocomplete-wrapper');

            autocomp_wrapper.addClass('show');
            if (autocomp_wrapper.find('.search-autocomplete__loading').length === 0){
                autocomp_wrapper.html(loader);
            }
        });

        $('#searchField').keyup(delay(function (e) {
            var autocomp_wrapper = $('.search-autocomplete-wrapper');
            if ($(this).val().length < 1) {
                clearAndHideWrapper($('.search-autocomplete-wrapper'));
            }
            $.ajax({
                type: 'post',
                url: '/search/',
                data: {ajax: 1, query: $(this).val()},
                dataType: 'json',
                before: function (){

                },
                success: function(response){
                    if (response.status === 'success') {
                        if (response.result.length === 0) {
                            autocomp_wrapper.html(`<div style="padding: 15px">${response.message}</div>`);
                        } else {
                            let html = response.result.map(function (el) {
                                return `<a class="search-autocomplete-item" href="${el.url}" >${el.title} <span>${el.label}</span></a>`;
                            });

                            autocomp_wrapper.html(html.join(''));
                        }
                    } else if (response.status === 'error'){
                        autocomp_wrapper.html(`<div style="padding: 15px">${response.message}</div>`);
                    }
                }
            });
        }, 1000));

        $('body').click(function (event) {
            if (!$(event.target).is('.search-autocomplete-wrapper')) {
                clearAndHideWrapper($('.search-autocomplete-wrapper'));
                if (jQuery('#searchField').hasClass('is-blur')) {
                    if (window.innerWidth < 1367) {
                        //jQuery('#searchField').val('').removeClass('sidenav-mobile__wide');
                        jQuery('#searchSwitcherCloseN').css('display', 'none');
                        //jQuery('#searchToShow').css('width', 'auto');
                        //jQuery('#collapseButtonClose').css('display', 'block');
                        jQuery('#searchField').val('').removeClass('is-blur');
                    } else {
                        jQuery('#searchField').val('').removeClass('is-blur');
                        jQuery('#searchSwitcherCloseN').css('display', 'none');
                    }
                }
            }
        });
    });

    function delay(fn, ms) {
        let timer = 0;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(fn.bind(this, ...args), ms || 0)
        }
    }

    function clearAndHideWrapper(autocomp_wrapper) {
        autocomp_wrapper.html('').removeClass('show');
    }

    function updateElementStylesOnSearchInit(mode = 'toggle') {
        if (mode === 'add'){
            $('#whitediv').addClass('update-zindex');
            $('nav.navbar').addClass('update-shadow');
        } else {
            $('#whitediv').toggleClass('update-zindex');
            $('nav.navbar').toggleClass('update-shadow');
        }
    }
});


