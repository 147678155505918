import './vendor/swiper/swiper.min.js'
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/modal';
import './vendor/tooltip.min'
import './vendor/flatpickr'
import './old/fenix4_scripts'
import './old/search-autocomplete'
import './old/fenix4_scripts-den'
import './old/fenix4_lazy_observer'
import 'jquery-validation'
import './localization/messages_sv'
import './old/fenix4_scripts-sa'
import './vendor/choices.min'
import './old/location-module'
// import './vendor/cookie-notice/front.min'
import 'iscroll'
import 'jquery-drawer'
import './scripts/clock-to-reveal'
// import './cookie-consent'

import '../styles/index.scss'


jQuery.validator.addMethod("fenixSEPhone", function(value, element) {
    return /^([0|+[0-9]{1,9})?([\w.\-])?([1-9][0-9]{3,9})$/.test( value );
}, 'Fel format på telefonnummer (prova 0XXXXXXXXXX eller +4XXXXXXXXXX)');