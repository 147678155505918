// ALL WORKING SCRIPTS ARE IN 
// fenix4_scripts.MIN.js
// THE BELOW IS for newly added code
jQuery(function ($) {
    $( document ).ready(function() {

        function getBaseUrl() {
            let re = new RegExp(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/);
            let urlArr = re.exec(window.location.href);
            return urlArr[0];
        }

        var web_site = getBaseUrl();

        const vars = {
            "search_ph_long"    : "Sök om begravningar, juridik och dödsboet",
            "search_ph_short"   : "Sök"
        }

        let dataFromJson = [];
        $.ajax({
            url: web_site + '/wp-content/themes/fenix4/_env/_js_trans.php',
            type: 'POST',
            data: JSON.stringify(vars),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            async: false,
            success: function( json ) {
                dataFromJson = json;
            }
        });

        // console.log('Hej scripts-den!');

        // Zozzy: Search result tabs handling
        /* if ( $('body.search-result-page').length ) { // for search result page
            var qtyCities = $("#searchFieldString").data("c-qty");
            if (qtyCities !== 0) { */

        // Zozzy: makes Orter Tab active
        /* $(".tab-item").removeClass('active-tab');
        $("#offices-result").addClass('active-tab');
        $('.search-tabs-content').addClass('hidden-result');
        $(".content-2").removeClass('hidden-result');
        */

        // Zozzy: scrolls search result tabs to the left on mobile
        /* $('.scrolling-wrapper').ready(function() {
            $('.scrolling-wrapper').delay(1000).animate({
                scrollLeft: $('.active-tab').offset().left
            }, 400, function() {
            });
        }); */
        /*    }
        } */

        // Forplanering pages: remove container
        if ($('body').is('.page-id-67183, .page-id-67202, .parent-pageid-67183')) {
            $('#content div.container:not(footer .container)').removeClass('container');
            $('.justify-content-between')[0].style.setProperty( 'justify-content', 'normal', 'important' );
        }

        // DEN: Trigger modal popup when ?konkjmf=1 - DEPRECATED 2019-09-25
        /* $.urlParam = function(name){
          var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
          if (results==null) {
             return null;
          }
              return decodeURI(results[1]) || 0;
        }

        if ($.urlParam('konkjmf') == '1' || $.urlParam('konkjmf') == 'yes' || $.urlParam('konkjmf') == 'true' ) {
            $('#showPrisdiagram').click();
        } */

        // 2019-11-13 Zozzy: moved from fenix_script.js row 238 minimizing issue
        function searchBarChanging() {
            var winWidth = $(window).width();
            var inp = $("#searchField");
            var trig = $('#triggerPressSubmit');
            if ( winWidth < 576) {

                // inp.attr("placeholder", dataFromJson.search_ph_short);
                if (inp.hasClass('search-input-pc')) {
                    inp.removeClass('search-input-pc').addClass('search-input-mob');
                }

                if (trig.hasClass('trigger-press-submit-pc')) {
                    trig.removeClass('trigger-press-submit-pc').addClass('trigger-press-submit-mob');
                }
            } else {
                if (inp.hasClass('header-top__input') || inp.hasClass('sidenav-mobile__input')) {
                    // inp.attr("placeholder", dataFromJson.search_ph_short);
                }else{
                    // inp.attr("placeholder", dataFromJson.search_ph_long);
                }

                if (inp.hasClass('search-input-mob')) {
                    inp.removeClass('search-input-mob').addClass('search-input-pc');
                }

                if (trig.hasClass('trigger-press-submit-mob')) {
                    trig.removeClass('trigger-press-submit-mob').addClass('trigger-press-submit-pc');
                }
            }
        }

        function hideClearMob() {
            var winWidth = $(window).width();
            if (winWidth < 576) {
                var inpVal = $('#searchField').val();
                // console.log(inpVal);
                if (inpVal !== '') {
                    $('#clearSearch').css('display', 'block');
                } else {
                    $('#clearSearch').css('display', 'none');
                }
            }
        }

        (function () {
            $('#clearSearch').on( "click", hideClearMob);
        }());

        (function () {
            $('#searchField').on( "keyup", hideClearMob);
        }());

        (function () {
            searchBarChanging();
        }());

        $(window).resize(function () {
            searchBarChanging();
        });

    }); // END of $( document ).ready(...)
}); /* jQuery */